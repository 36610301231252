/* You can add global styles to this file, and also import other style files */
@import "variables.scss";
@import "./themes/cub.scss";

/*
 * Base structure
 */

 .cub-body {
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  label {
    margin-bottom: 0;
  }
 }

/* Move down content because we have a fixed navbar that is 50px tall */
body {
  /* padding-top: 5px;  */
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/*
 * Typography
 */

h1 {
  margin-bottom: 20px;
  padding-bottom: 9px;
  border-bottom: 1px solid #eee;
}

span.highlight {
  background-color: yellow;
}
.sys-list-container > .active span.highlight {
  color: #444;
}

/* menu stuff */
.mat-mdc-menu-content div.group-label {
  padding: 5px;
  padding-left: 10px;
  color: #333;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  width: 100%;
  border-top: solid 1px #ddd;
  border-bottom: solid 1px #ddd;
  background: #f3f3f3;
}

.mat-action-row .mat-mdc-raised-button {
  margin-right: 15px;
}

/*
 * Dashboard
 */

.dashboard {
  background-color: #fff;
  padding: 20px;
  padding-bottom: 0;
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-body-content {
    padding: 5px;
  }
}
.dashboard-no-padding {
  background-color: #fff;
  padding-bottom: 0;
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-body-content {
    padding: 5px;
  }
}

.mat-toolbar.dashboard-toolbar {
  background-color: #fff;
  border-bottom: solid 1px #ddd;
  margin-bottom: 20px;
}

.dashboard-toolbar .helper {
  font-size: 13px;
  color: #aaa;
  margin-right: 10px;
}
.dashboard .mat-toolbar-single-row {
  padding: 0;
}

.dashboard-container {
  // padding: 0 20px 20px 20px;
}

.dashboard-toolbar i {
  color: $cnt-blue;
}

.dashboard-toolbar-example-spacer {
  // This fills the remaining space, by using flexbox.
  // Every toolbar row uses a flexbox row layout.
  flex: 1 1 auto;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.dashboard-toolbar label.mat-button-toggle-label {
  margin-bottom: 2px;
  font-size: 17px;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.dashboard .mat-button-toggle-checked {
  background-color: $cnt-blue;
  color: #fff;
}

.mat-mdc-card.dashboard-widget-simple {
  padding: 15px;
  text-align: left;
  overflow: hidden;
}
.mat-mdc-card.dashboard-widget-simple > mat-card-title {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
  margin-bottom: 10px;
  color: #888;
}
.mat-mdc-card.dashboard-widget-simple > mat-card-title > span {
  font-size: 10px;
  text-transform: lowercase;
  font-weight: normal;
  margin-bottom: 10px;
  color: #888;
}
.mat-mdc-card.dashboard-widget-simple > mat-card-subtitle {
  font-size: 34px;
  overflow: hidden;
}
.mat-mdc-card.dashboard-widget-simple > mat-card-subtitle > .unit {
  font-size: 18px;
  color: #777;
  margin-left: 5px;
}
.mat-mdc-card.dashboard-widget-simple > mat-card-subtitle i {
  margin-right: 6px;
}
.mat-mdc-card.dashboard-bold {
  padding: 20px;
  color: #fff;
  background: #ffa726; //rgb(33, 150, 243);
}
.mat-mdc-card.dashboard-bold > mat-card-title {
  border-bottom: solid 1px #ffe0b2; //rgb(187, 222, 251);
}
.mat-mdc-card.dashboard-bold > mat-card-subtitle {
  color: #fff3e0;
}

.color-purple {
  color: #4a148c;
}
.color-orange {
  color: #ef6c00;
}
.color-green {
  color: #1b5e20;
}
.color-brown {
  color: #6d4c41;
}
.color-pink {
  color: #9f2326;
}
.color-blue {
  color: #21366c;
}

/* Placeholders */
.placeholders {
  padding-bottom: 3rem;
}

.placeholder img {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

/*  listing screen stuff  */
.cub-sys-list {
  display: flex;
  height: calc(100vh - 50px - 60px);
  width: 320px;
  flex-flow: column;
  border-right: solid 1px #ddd;
  z-index: 400;
  background-color: #fff;
}
.cub-sys-list-assets {
  display: flex;
  height: calc(100vh - 64px - 65px - 30px);
  width: 320px;
  flex-flow: column;
  border-right: solid 1px #ddd;
  z-index: 400;
  background-color: #fff;
}

@media (max-width: 1300px) {
  .cub-sys-list {
    top: 64px;
    left: 66px;
    z-index: 400;
  }
}

.cub-sys-home {
  width: 100%;
  height: calc(100vh - 69px);

  .top-toolbar {
    height: 50px;
    background-color: #546b9f;

    > div.title {
      font-size: 20px;
      margin-top: -5px;
      color: #fff;
      margin-right: 40px;
      cursor: default;
    }
    > div.selected {
      background-color: #3b5286;
      color: #fff;
      border-top: solid 3px $cnt-orange;
    }
    > div {
      display: inline-block;
      text-align: center;
      color: #ccc;
      padding: 14px 25px;
      cursor: pointer;

      > fa-icon,
      > div {
        display: inline-block;
      }
      > div {
        margin-left: 8px;
      }
    }
  }
  .cub-sys-main {
    height: calc(100vh - 50px - 50px);
    display: grid;
    grid-template-columns: 319px auto;
  }
  .cub-sys-main-assets {
    height: calc(100vh - 64px - 55px - 37px);
    display: grid;
    grid-template-columns: 319px auto;
  }
  .cub-sys-full {
    height: calc(100vh - 64px - 55px - 37px);
  }
}

.cub-sys-outlet {
  flex: 1;
  height: calc(100vh - 48px);
  overflow-y: auto;
  z-index: 300;
  background-color: #fff;
}

.cub-sys-outlet-assets {
  flex: 1;
  overflow-y: auto;
  z-index: 300;
  background-color: #fff;
  width: 100%;
}

.list-row.container {
  font-size: 13px;
  border-bottom: solid 1px #ddd;
  padding: 5px 10px 5px 5px;
  border-left: solid 5px #fff;
}
.list-row:hover {
  background-color: #f3f3f3;
  cursor: pointer;
}

.list-control-top {
  background-color: #3b5286;
}

.sys-list-container {
  text-align: center;
  overflow-y: auto;
  flex: 3000 2 auto;
  width: 320px;
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
  display: flex;
}
.sys-list-container.cdk-virtual-scroll-viewport {
  overflow-y: scroll;
}
.sys-list-container .fa-circle-o-notch {
  text-align: middle;
  margin-top: 20px;
}
/* shows the active item in the list
  .sys-list-container > .active > div,
  .sys-list-container > .active > div.list-row:hover
  {
      background-color: $primary-color;
      border-left: solid 5px $primary-color;
      color: #fff;
  }
  .sys-list-container > .active  .list-item-desc
  {
      background-color: $primary-color;
      border-left: solid 5px $primary-color;
      color: #fff;
  }
  */
.sys-list-container > .error > div,
.sys-list-container > .error > div.list-row:hover {
  border-left: solid 5px red;
}
.sys-list-container > .warning > div,
.sys-list-container > .warning > div.list-row:hover {
  border-left: solid 5px $cnt-orange-900;
}

.sys-list-header {
  flex: 1 2 auto;
}

.sys-list-footer {
  flex: 1 2 auto;
  padding: 5px;
  background-color: #f3f3f3;
  border-top: solid 1px #ddd;
}

.sys-list-group-title {
  padding: 5px 10px;
  border-bottom: solid 1px #eee;
  cursor: pointer;
  text-align: center;
  padding: 5px 10px;
  border-bottom: solid 1px #eee;
  cursor: pointer;
}
.sys-list-group-title:hover {
  background-color: #eee;
}
.sys-list-group-title > i {
  margin-right: 8px;
  float: left;
  margin-top: 3px;
}
.sys-list-group-title > i:last-child {
  margin-right: 8px;
  float: right;
  margin-top: 3px;
}
.sys-list-group-title > span {
  margin-right: 14px;
  font-weight: bold;
}

.sys-list-group-list {
  display: block;
}
.sys-list-group-list > div {
  > div:first-child {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
  }

  >div:last-child {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: right;
  }

  display: grid;
  grid-template-columns: 230px 50px;
  padding: 5px 10px;
  border-bottom: solid 1px #eee;
  cursor: pointer;
}
.sys-list-group-list > div:hover {
  background-color: #eee;
}
.sys-list-group-list > div > .count {
  color: #888;
  float: right;
}

/* lease worksheet */
.lease-worksheet-section {
  border: solid 1px #ddd;
  margin-bottom: 20px;
}

.lease-worksheet-section > table {
  margin-bottom: 0;
}

.wks-header {
  background-color: #eee;
  color: #444;
  padding: 2px;
  text-align: center;
  font-style: italic;
  font-size: 14px;
}
.wks-header > i {
  float: right;
  margin: 4px 10px;
  cursor: pointer;
}

.lease-worksheet-section table th,
.lease-worksheet-section table td {
  font-size: 11px;
  border-right: solid 1px #ddd;
  text-align: center;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
}
.lease-worksheet-section table th {
  padding: 6px;
  overflow: hidden;
}
.lease-worksheet-section table tr.dirty td.cell-control {
  background-color: $primary-color;
  color: #fff;
}

.lease-worksheet-section table td input {
  border: solid 1px #fff;
  padding: 6px;
  width: 100%;
  background-color: transparent;
}
.lease-worksheet-section table td input:focus {
  border: solid 1px $primary-color;
  padding: 6px;
  width: 100%;
}
.lease-worksheet-section table td.cell-control {
  padding-top: 5px;
}
.lease-worksheet-section table td i {
  margin: 10px 5px;
  cursor: pointer;
}

.lease-worksheet-section table td div {
  border: none;
  padding: 6px;
  width: 100%;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.list-toolbar div.mat-button-toggle-label-content {
  padding-left: 10px;
  padding-right: 10px;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.cub-card .mat-card-header-text {
  width: 100%;
}
.lease-contact-list .cub-card table thead th:first-child,
.lease-contact-list .cub-card table tr td:first-child {
  text-indent: 0;
}

/* cub table card */
.cub-table-card {
  margin: 10px;
  width: calc(100% - 30px);
  margin-bottom: 20px;
  height: auto;
  overflow: hidden;
}
.cub-table-card.mat-mdc-card {
  padding: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.cub-table-card div.mat-card-header-text {
  margin: 0;
}

.cub-table-card .mat-mdc-card-header {
  padding: 24px;
}
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
.cub-table-card .mat-mdc-card-header,
.cub-table-card .mat-card-header-text {
  width: 100%;
}

.cub-table-card .mat-mdc-card-title {
  margin-bottom: 0;
}
.cub-table-card .mat-mdc-card-title > span {
  font-size: 20px;
}

.cub-table-card mat-checkbox {
  float: right;
}

.cub-table-card table {
  margin-bottom: 20px;
}

.cub-table-card table td {
  border-bottom: solid 1px #eee;
  padding: 6px 0;
}
.cub-table-card table th {
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
  font-size: 12px;
  border-bottom: solid 1px #eee;
  padding: 6px 0;
}
.cub-table-card table thead th:first-child,
.cub-table-card table tr td:first-child {
  text-indent: 24px;
}
.cub-table-card table thead th:last-child,
.cub-table-card table tr td:last-child {
  text-indent: 24px;
  padding-right: 24px;
  //direction:rtl;
}
.cub-table tr:hover td {
  background-color: #eee;
}

.text-overflow-dynamic-container {
  position: relative;
  max-width: 100%;
  padding: 0 !important;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  vertical-align: text-bottom !important;
}
.text-overflow-dynamic-ellipsis {
  position: absolute;
  white-space: nowrap;
  overflow-y: visible;
  overflow-x: hidden;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  top: 0;
  left: 0;
}
.text-overflow-dynamic-container:after,
.text-overflow-dynamic-ellipsis:after {
  content: "-";
  display: inline;
  visibility: hidden;
}

.dashboard .cub-card {
  padding: 0;
}
.dashboard .cub-card h5 {
  margin-bottom: 0;
}

.cub-card-header {
  border-bottom: solid 1px #ddd;
  padding: 20px;
}
.cub-card-content {
  padding: 20px;
}

.cub-card-header button.more {
  float: right;
  margin-left: 30px;
  min-width: 65px;
}

.cub-table {
  width: 100%;
  margin-bottom: 20px;
  font-size: 14px;
}

.cub-table td {
  border-bottom: solid 1px #eee;
  padding: 6px 0;
}
.cub-table th {
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
  font-size: 12px;
  border-bottom: solid 1px #eee;
  padding: 6px 0;
}
.cub-table thead th:first-child,
.cub-table tr td:first-child {
  text-indent: 24px;
}
.cub-table thead th:last-child,
.cub-table tr td:last-child {
  text-indent: 24px;
  padding-right: 24px;
  //direction:rtl;
}

.label {
  color: #888;
}

.note {
  font-style: italic;
  font-size: 14px;
}

.mat-nav-list cub-left-menu-button .menu-main-list.mat-mdc-list-item {
  height: 40px;
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
  .mat-list-item-content {
    padding-left: 0;
    padding-right: 0;
  }
}

.office-oak-brook {
  background-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    url(assets/img/offices/oakBrook.jpg);
}
.office-kc {
  background-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    url(assets/img/offices/kc.jpg);
}
.office-suffolk {
  background-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    url(assets/img/offices/suffolk.jpg);
}
.office-la {
  background-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    url(assets/img/offices/la.jpg);
}
.office-nj {
  background-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    url(assets/img/offices/nj.jpg);
}
.office-norfolk {
  background-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    url(assets/img/offices/norfolk.jpg);
}
.office-oakland {
  background-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    url(assets/img/offices/oakland.jpg);
}
.office-houston {
  background-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    url(assets/img/offices/houston.jpg);
}
.office-fla {
  background-image: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    url(assets/img/offices/fla.jpg);
}
.bottom-oak-brook {
  background: linear-gradient(
      0deg,
      rgba(33, 55, 108, 0.9),
      rgba(33, 55, 108, 0.9)
    ),
    url(assets/img/offices/oakBrook.jpg) repeat;
}
.bottom-kc {
  background: linear-gradient(
      0deg,
      rgba(33, 55, 108, 0.9),
      rgba(33, 55, 108, 0.9)
    ),
    url(assets/img/offices/kc.jpg) repeat;
}
.bottom-suffolk {
  background: linear-gradient(
      0deg,
      rgba(33, 55, 108, 0.9),
      rgba(33, 55, 108, 0.9)
    ),
    url(assets/img/offices/suffolk.jpg) repeat;
}
.bottom-la {
  background: linear-gradient(
      0deg,
      rgba(33, 55, 108, 0.9),
      rgba(33, 55, 108, 0.9)
    ),
    url(assets/img/offices/la.jpg) repeat;
}
.bottom-nj {
  background: linear-gradient(
      0deg,
      rgba(33, 55, 108, 0.9),
      rgba(33, 55, 108, 0.9)
    ),
    url(assets/img/offices/nj.jpg) repeat;
}
.bottom-norfolk {
  background: linear-gradient(
      0deg,
      rgba(33, 55, 108, 0.9),
      rgba(33, 55, 108, 0.9)
    ),
    url(assets/img/offices/norfolk.jpg) repeat;
}
.bottom-oakland {
  background: linear-gradient(
      0deg,
      rgba(33, 55, 108, 0.9),
      rgba(33, 55, 108, 0.9)
    ),
    url(assets/img/offices/oakland.jpg) repeat;
}
.bottom-houston {
  background: linear-gradient(
      0deg,
      rgba(33, 55, 108, 0.9),
      rgba(33, 55, 108, 0.9)
    ),
    url(assets/img/offices/houston.jpg) repeat;
}
.bottom-fla {
  background: linear-gradient(
      0deg,
      rgba(33, 55, 108, 0.9),
      rgba(33, 55, 108, 0.9)
    ),
    url(assets/img/offices/fla.jpg) repeat;
}

.point-like-tooltip {
  white-space: pre-line;
}

.button-standard.mat-mdc-unelevated-button,
.button-standard.mat-mdc-raised-button {
  min-width: 90px;
}

::ng-deep .mat-mdc-tooltip {
  white-space: pre-line !important;
  word-break: break-all;
  max-width: 500px;
  text-align: center;
}

.button-standard.mat-mdc-raised-button {
  min-width: 90px;
}
.button-standard.button-delete {
  min-width: 90px;
  color: $cnt-red;
}

.cub-body button:focus {
  outline: none;
}
.cub-body input:focus {
  outline: none;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.cnt .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 34px;
}


/* "fixed" for ng 15 material upgrade. This resolves some of the theming issues with that upgrade.  At some point, we should fix these by applying proper themes?? */
.cub-body .mat-mdc-text-field-wrapper.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
  padding: 0;
}
.cub-body .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-bottom: 0;
  min-height: 50px;
}
.cub-body .mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill .mdc-floating-label--float-above {
  font-size: 14px;
}
.cub-body .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 24px;
}
.cub-body .mat-datepicker-toggle-default-icon {
  margin-top: 8px;
}
.cub-body .mat-datepicker-toggle {
  .mat-mdc-icon-button {
    --mdc-icon-button-icon-size: 18px;
  }
}
.cub-body .mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 20px;
}
.cub-body .separator .mdc-list-item__primary-text {
  display: inline-block;
  width: 84%;
}
.cub-body .mdc-data-table__cell {
  font-size: 14px;
}
.cub-body .mat-mdc-checkbox label {
  font-weight: 400;
}
.cub-body .mat-mdc-slide-toggle label {
  font-weight: 400;
}
.cub-body .mat-mdc-text-field--focused {
  background-color: white;
}

.appraisalSnackBar {
  padding-top: 130px;
}

.mat-mdc-tab-labels {
  padding-bottom: 1px;
  border-bottom: solid 1px #ddd;
}
