.app-loading {
  .logo {
    margin-top: 10px;
    width: 200px;
    height: 118px;

    // this way asset gets processed by webpack
    background: url(./assets/img/cnt-shield-blue.png) center center no-repeat;
  }
}
