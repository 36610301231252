$primary-color: #21376c; //3A66A7
$primary-color-dark: #21386c;
$dark-bg: #434248; //313436

$cnt-blue: #21376c;
$cnt-blue-50: #e4e7ed;
$cnt-blue-100: #bcc3d3;
$cnt-blue-200: #909bb6;
$cnt-blue-300: #647398;
$cnt-blue-400: #425582;
$cnt-blue-500: #21376c;
$cnt-blue-600: rgb(2, 2, 2);
$cnt-blue-700: #182a59;
$cnt-blue-800: #14234f;
$cnt-blue-900: #0b163d;

$cnt-grid-gray: #ddd;
$cnt-gray-50: #eee;
$cnt-gray-100: #c7c6c8;
$cnt-gray-200: #a1a1a4;
$cnt-gray-300: #7b7b7f;
$cnt-gray-400: #5f5e63;
$cnt-gray-500: #434248;
$cnt-gray-600: #3d3c41;
$cnt-gray-700: #343338;
$cnt-gray-800: #2c2b30;
$cnt-gray-900: #1e1d21;

$cnt-orange: #ea9721;
$cnt-orange-50: #fef7e9;
$cnt-orange-100: #fbebc8;
$cnt-orange-200: #f9dea4;
$cnt-orange-300: #f7d07f;
$cnt-orange-400: #f5c663;
$cnt-orange-500: #f3bc48;
$cnt-orange-600: #f1b641;
$cnt-orange-700: #efad38;
$cnt-orange-800: #eda530;
$cnt-orange-900: #ea9721;

$cnt-bright-blue: #0f99d6;
$cnt-bright-blue-50: #e2f3fa;
$cnt-bright-blue-100: #b7e0f3;
$cnt-bright-blue-200: #87cceb;
$cnt-bright-blue-300: #57b8e2;
$cnt-bright-blue-400: #33a8dc;
$cnt-bright-blue-500: #0f99d6;
$cnt-bright-blue-600: #0d91d1;
$cnt-bright-blue-700: #0b86cc;
$cnt-bright-blue-800: #087cc6;
$cnt-bright-blue-900: #046bbc;

$cnt-light-blue: #e3f2fd;
$cnt-light-blue-50: #fcfdff;
$cnt-light-blue-100: #f7fbfe;
$cnt-light-blue-200: #f1f9fe;
$cnt-light-blue-300: #ebf6fe;
$cnt-light-blue-400: #e7f4fd;
$cnt-light-blue-500: #e3f2fd;
$cnt-light-blue-600: #e0f0fd;
$cnt-light-blue-700: #dceefc;
$cnt-light-blue-800: #d8ecfc;
$cnt-light-blue-900: #d0e8fc;

$cnt-green: #006d15;

$cnt-red: #b22222;
$cnt-light-red: #ffcccb;

// from march madness app
$tan: #eeddd0;
$yellow: #fbc366;
$orange: #fea72a;
$darkOrange: #d2732a;
$brown: #a44d21;
$black: #2d0d04;
$white: #fffffe;
$brightOrange: #f78623;
