@use '@angular/material' as mat;
@use "sass:map";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
@include mat.all-component-typographies();
@include mat.core();

$mat-cntblue: (
    50 : #e4e7ed,
    100 : #bcc3d3,
    200 : #909bb6,
    300 : #647398,
    400 : #425582,
    500 : #21376c,
    600 : #1d3164,
    700 : #182a59,
    800 : #14234f,
    900 : #0b163d,
    A100 : #768dff,
    A200 : #4363ff,
    A400 : #1039ff,
    A700 : #002af6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$mat-cntorange: (
    50 : #fef7e9,
    100 : #fbebc8,
    200 : #f9dea4,
    300 : #f7d07f,
    400 : #f5c663,
    500 : #f3bc48,
    600 : #f1b641,
    700 : #efad38,
    800 : #eda530,
    900 : #ea9721,
    A100 : #ffffff,
    A200 : #fff8ef,
    A400 : #ffe1bc,
    A700 : #ffd6a2,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-cntgray: (
    50 : #e8e8e9,
    100 : #c7c6c8,
    200 : #a1a1a4,
    300 : #7b7b7f,
    400 : #5f5e63,
    500 : #434248,
    600 : #3d3c41,
    700 : #343338,
    800 : #2c2b30,
    900 : #1e1d21,
    A100 : #7575f6,
    A200 : #4545f3,
    A400 : #0606ff,
    A700 : #0000eb,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-cntlightblue: (
    50 : #e2f3fa,
    100 : #b7e0f3,
    200 : #87cceb,
    300 : #57b8e2,
    400 : #33a8dc,
    500 : #0f99d6,
    600 : #0d91d1,
    700 : #0b86cc,
    800 : #087cc6,
    900 : #046bbc,
    A100 : #e5f2ff,
    A200 : #b2d9ff,
    A400 : #7fc0ff,
    A700 : #65b3ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.// The warn palette is optional (defaults to red).
$cub-app-primary: mat.m2-define-palette($mat-cntblue);
$cub-app-accent:  mat.m2-define-palette($mat-cntorange, 600, 700, 900);
$cub-app-warn:    mat.m2-define-palette(mat.$m2-red-palette);


// these typography settings are used to fix the font formatting issues brought upon by angular 15 changes.  Letter spacing is the main fix here

/* Styles to be applied to buttons */
$my-custom-button: mat.m2-define-typography-level(
    $font-family: 'Roboto, "Helvetica Neue", sans-serif',
    $font-weight: 500,
    $font-size: 14px,
    $line-height: 1,
    $letter-spacing: .01em
  );

/* Styles to be applied to input-fields */
$my-custom-input: mat.m2-define-typography-level(
    $font-family: 'Roboto, "Helvetica Neue", sans-serif',
    $font-weight: 500,
    $font-size: 14px,
    $line-height: 1,
    $letter-spacing: .01em
  );

/* Merge custom configs into existing config */
$my-typography-config: map.merge(mat.m2-define-typography-config(
    $font-family: 'Roboto, "Helvetica Neue", sans-serif',
    $body-1: mat.m2-define-typography-level(16px, 21px, 400),
    $body-2: mat.m2-define-typography-level(16px, 25px, 500),
    $caption: mat.m2-define-typography-level(14px, 21px, 400),
      /* 'button'-property will work out of the box */
      $button: $my-custom-button ),
    (
      /* 'input'-property will have to be assigned under '@mixin typography' further below */
      'input': $my-custom-input ));

/* Apply custom config */
@include mat.all-component-typographies($my-typography-config);
@include mat.typography-hierarchy($my-typography-config);

/* Let's assign the custom property 'input' that we defined above */
@mixin typography($theme) {
  $custom-typography-config: mat.m2-get-typography-config($theme);
  .mat-mdc-form-field {
    @include mat.m2-typography-level($custom-typography-config, 'input')
  }
}

/* Define custom app-theme based on custom-configs */
$cub-app-theme: mat.m2-define-light-theme((
  color: (
    primary: $cub-app-primary,
    accent: $cub-app-accent,
    warn: $cub-app-warn,
  ),
  typography: $my-typography-config,
));

/* Apply custom app-theme */
@include typography($cub-app-theme);

$typography-config: mat.m2-get-typography-config($cub-app-theme);

/*
// uncommenting this section will get cub back to the way it was before the angular 15 migration

// Create the theme object (a Sass map containing all of the palettes).
$cub-app-theme: mat.define-light-theme($cub-app-primary, $cub-app-accent, $cub-app-warn);

$typography-config: mat.get-typography-config($cub-app-theme);
*/

/*

// Create the theme object (a Sass map containing all of the palettes).
$cub-app-theme: mat.define-light-theme($cub-app-primary, $cub-app-accent, $cub-app-warn);

$typography-config: mat.get-typography-config($cub-app-theme);
*/

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($cub-app-theme);
